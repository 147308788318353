import "@hotwired/turbo-rails";
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";

import "../controllers";
import "../styles/application.css";

import YesNoControl from "../components/yes_no_control";

Alpine.plugin(focus);

Alpine.data("yesno", YesNoControl);

window.Alpine = Alpine;
Alpine.start();
